export default {
    main: {
        login: 'เข้าสู่ระบบ',
        signup: 'สมัครสมาชิก',
        language: 'ภาษา',
        toTop: 'ไปด้านบน',
        licence: 'ใบอนุญาต',
        profile: 'โปรไฟล์ส่วนตัว',
        nodata: 'ไม่มีข้อมูล',
        logout: 'ออกจากระบบ',
        notify: 'การแจ้งเตือน',
        all: 'ทั้งหมด',
        system: 'ประกาศจากระบบ',
        activity: 'กิจกรรม',
        clear: 'ล้างข้อมูลทั้งหมด',
        more: 'โหลดเพิ่มเติม',
        wallet: 'กระเป๋าเงิน',
        deposit: 'ฝากเงิน',
        withdraw: 'ถอนเงิน',
        trans: 'ธุรกรรม',
        currency: 'สกุลเงินที่ฝาก',
        network: 'เครือข่าย',
        address: 'ที่อยู่การฝาก',
        mini: 'เงินฝากขั้นต่ำ',
        send: 'ส่งเฉพาะ USDT ไปยังที่อยู่การฝากนี้ เหรียญจะถูกฝากโดยอัตโนมัติหลังจากยืนยันเครือข่าย 6 ครั้ง ที่อยู่สมาร์ทคอนแทรคไม่รองรับ (ติดต่อเราได้)',
        withAddress: 'ที่อยู่ถอนเงิน',
        withEnter: 'กรอกที่อยู่ของคุณ',
        tag: 'ป้ายกำกับ',
        tagEnter: 'กรุณาเข้า',
        amount: 'จำนวนเงินที่ถอน',
        security: 'เพื่อความปลอดภัย การถอนเงินที่มีมูลค่ามากหรือมีลักษณะที่น่าสงสัย อาจใช้เวลาตรวจสอบ 1-6 ชั่วโมง ขอบคุณที่อดทนรอ!',
        today: 'วันนี้',
        week: 'สัปดาห์นี้',
        month: 'เดือนนี้',
        year: 'ปีนี้',
        myWallet: 'กระเป๋าเงินของฉัน',
        available: 'ยอดเงินที่มี',
        fee: 'ค่าธรรมเนียม',
        chat: 'แชท',
        online: 'ออนไลน์',
        message: 'พิมพ์ข้อความ',
        rules: 'กฎ'
    },
    login: {
        email: "กรุณากรอกอีเมล",
        incorrect: "รูปแบบอีเมลไม่ถูกต้อง",
        password: "กรุณากรอกรหัสผ่าน",
        characters: "รหัสผ่านต้องมีความยาวระหว่าง 6 ถึง 15 ตัวเลขหรืออักษร.",
        account: "บัญชี",
        bindAccount: 'ผูกบัญชี',
        login: "เข้าสู่ระบบ",
        register: "ลงทะเบียน",
        userEmail: "อีเมลของคุณ",
        enterEmail: "กรอกอีเมลของคุณ",
        userPas: "รหัสผ่านของคุณ",
        enterPas: "กรอกรหัสผ่านของคุณ",
        forget: "ลืมรหัสผ่าน?",
        or: "หรือ",
        agree: "ฉันได้อ่านและตกลงกับข้อกำหนดและเงื่อนไข",
        resAndLogin: "ลงทะเบียนและเข้าสู่ระบบ",
        policy: "เว็บไซต์นี้ได้รับการปกป้องโดย reCAPTCHA และนโยบายความเป็นส่วนตัวและข้อกำหนดในการให้บริการของ Google ใช้บังคับ"
    },
    profile: {
        password: "กรุณาใส่รหัสผ่านใหม่",
        long: "รหัสผ่านต้องมีตัวเลข ตัวอักษร หรือช่องว่างอย่างน้อยหนึ่งตัว และมีความยาวระหว่าง 6-15 อักขระ",
        newPas: "รหัสผ่านยืนยันไม่ตรงกับรหัสผ่านใหม่",
        original: "กรุณาใส่รหัสผ่านเดิม",
        personal: "การตั้งค่าส่วนตัว",
        change: "เปลี่ยนชื่อเล่นและข้อมูลของคุณ",
        username: "ชื่อผู้ใช้ของคุณ",
        hide: "ซ่อนชื่อเล่นของฉัน",
        private: "โปรไฟล์ส่วนตัว",
        closing: "การปิดบัญชีของคุณ",
        closeDatail: "คุณสามารถปิดบัญชีของคุณได้โดยคลิกที่ปุ่ม “ปิดบัญชีของฉัน” ด้านล่าง ข้อมูลทั้งหมดของคุณจะถูกลบอย่างถาวรและไม่สามารถกู้คืนได้ในอนาคต (รวมถึงสิทธิประโยชน์ VIP ใด ๆ ด้วย)",
        closeMy: "ปิดบัญชีของฉัน",
        social: "สังคมออนไลน์",
        media: "เชื่อมต่อโซเชียลมีเดีย",
        connected: "เชื่อมต่อแล้ว",
        noConnect: "ยังไม่ได้เชื่อมต่อ",
        settings: "การตั้งค่า",
        account: "การตั้งค่าทั่วไปของบัญชีของคุณ",
        email: "อีเมล",
        verify: "กรุณายืนยันอีเมลของคุณ",
        factor: "การยืนยันตัวตนแบบสองขั้นตอน",
        enable: "เปิดการยืนยันตัวตนสองขั้นตอนเพื่อป้องกันบัญชีของคุณจากการเข้าถึงโดยไม่ได้รับอนุญาต",
        prohibit: "ห้าม",
        pas: "รหัสผ่าน",
        current: "รหัสผ่านปัจจุบันของคุณ",
        please: "กรุณาใส่รหัสผ่านปัจจุบันของคุณ",
        newPassword: "รหัสผ่านใหม่",
        paleseNew: "กรุณาใส่รหัสผ่านใหม่ของคุณ",
        repeat: "ยืนยันรหัสผ่าน",
        pleaseCurrent: "กรุณาใส่รหัสผ่านปัจจุบันของคุณ",
        changePas: "เปลี่ยนรหัสผ่าน",
        sessions: "เซสชัน",
        devices: "ตรวจสอบอุปกรณ์ของคุณ",
        history: "ประวัติการเล่นเกม",
        referrals: "การแนะนำ",
        VIP: "โปรแกรม VIP",
        check: "ตรวจสอบธุรกรรมทั้งหมดของคุณ",
        allTrans: "ธุรกรรมทั้งหมด",
        withdrawal: "การถอน",
        timeframe: "ช่วงเวลา",
        today: "วันนี้",
        deposits: "การฝากเงิน",
        chackGame: "ตรวจสอบเกมทั้งหมดของคุณ",
        chain: "เชน"
    },
    components: {
        search: "ค้นหา",
        sort: "เรียงตาม:",
        popular: "ยอดนิยม",
        providers: "ผู้ให้บริการ:",
        new: "ใหม่",
        hot: "ฮอต",
        notice: "ยังไม่มีการแจ้งเตือน",
        enable: "เปิดใช้งาน",
        cybet: "Cybet.com เป็นเครื่องหมายการค้าของ Raw Entertainment B.V. หมายเลขการจดทะเบียน 157205 จดทะเบียนที่ Korporaalweg 10, คูราเซา ได้รับอนุญาตจากรัฐบาลคูราเซาสำหรับการเล่นเกมออนไลน์ หมายเลขใบอนุญาต 8048/JAZ Raw Entertainment Ltd หมายเลขการจดทะเบียน HE421735 จดทะเบียนที่ Voukourestiou 25, Neptune House ชั้น 1 ห้อง/สำนักงาน 11 Zakaki, 3045, ลิมาซอล ไซปรัส เป็นสมาชิกของกลุ่ม Cybet และทำหน้าที่เป็นตัวแทนการชำระเงินและการดำเนินงานในนามของ Raw Entertainment B.V. (18+).",
        progress: "ความคืบหน้า VIP ของคุณ",
        discover: "ค้นพบประสบการณ์การเล่นเกมขั้นสุดยอดด้วยระบบ VIP สุดพิเศษของ CY.GAMES!",
        link: "ลิงค์ของคุณ",
        share: "รับ $1,000.00 สำหรับเพื่อนแต่ละคนที่คุณเชิญ รวมถึงค่าคอมมิชชั่นสูงสุด 25% จากการเดิมพันของพวกเขา สนุกกับค่าคอมมิชชั่นที่สม่ำเสมอไม่ว่าพวกเขาจะชนะหรือแพ้ในคาสิโนและสปอร์ตบุ๊คของเรา เริ่มสร้างรายได้เลย!",
        referralLink: "ลิงค์แนะนำของคุณ",
        code: "รหัสแนะนำของคุณ",
        yourReferrals: "การแนะนำทั้งหมดของคุณ",
        receive: "รับ",
        game: "เกมทั้งหมด",
        winnings: "เงินรางวัล",
        loosing: "ขาดทุน",
        wagered: "ผู้เล่นของคุณได้วางเดิมพัน",
        earned: "คุณได้รับเงิน",
        nothing: "ยังไม่มีสิ่งใดที่จะรับ",
        join: "เข้าร่วมชุมชนผู้เล่นชั้นยอดของเราและเพลิดเพลินกับสิ่งที่ดีที่สุดที่เกมออนไลน์มีให้",
        details: "รายละเอียด",
        unverified: "ยังไม่ได้รับการยืนยัน",
        verified: "อีเมลได้รับการยืนยันแล้ว",
        type: "ประเภทการทำธุรกรรม",
        date: "วันที่",
        total: "จำนวนเงินรวมที่เดิมพัน",
        methods: "วิธีการชำระเงิน",
        status: "สถานะ",
        device: "อุปกรณ์",
        location: "สถานที่",
        IP: "ที่อยู่ IP",
        last: "การใช้งานล่าสุด",
        tableGame: "เกม",
        bet: "เดิมพัน",
        multiplayer: "ผู้เล่นหลายคน",
        profit: "กำไร",
        searchGame: "ค้นหาเกม",
        provider: "ผู้ให้บริการ",
        ourGames: "เกมของเรา",
        myBets: 'เดิมพันของฉัน',
        allWins: "ทั้งหมดที่ชนะ",
        luckyWins: "ชัยชนะที่โชคดี",
        bigWins: "ชัยชนะครั้งใหญ่",
        latest: "การเดิมพันล่าสุด",
        player: "ชื่อผู้เล่น",
        time: "เวลา",
        amount: "จำนวนเงิน",
        coefficent: "สัมประสิทธิ์"
    },
    wallet: {
        noAdress: 'ที่อยู่ไม่สามารถว่างได้',
        submit: 'คำขอถอนเงินถูกส่งแล้ว รอการยืนยัน...',
        range: 'กรุณาใส่จำนวนเงินในช่วงการถอน'
    }
};
