// 线上环境的游戏配置
import { computed, ref, watch } from "vue";
import Storage from '@/Utils/ts/storage';
import storage from '@/Utils/ts/storage';
import { userContent, wallet, gameServerApi } from "@/Utils/ts/api";
import router from "@/router/index";
import { bus } from "@/Utils/bus";
import { initWebSocket, disconnectWebSocket, reconnectWebSocket } from '../ts/notificationWs';
import formatPrice from "../ts/formatPrice";
import { formatPoint } from "@/Utils/ts/formatPoint";
import { decimal_div, decimal_mul } from '@/Utils/decimal';
// import {bus} from "@/Utils/bus";
const loginStatus = ref(false);
const userInfo = ref({
    "id": "",
    "userName": "",
    "currentProgress": 0,
    "currentLevelValue": '0',
    "googleSecret": "",
    "nextProgress": "",
    "progress": "",
    "levelId": "",
    "googleUrl": "",
    "selectedBalanceType": "",
    "canGetUpReward": 0,
    "canGetUpRewardAmount": '',
    "currencyType": '',
    "imgUrl": "",
    "tell": null,
    "email": "",
    "sharCode": null,
    "gent": null,
    "nickName": null,
    "birth": null,
    "country": null,
    "province": null,
    "region": null,
    "address": null,
    "identityCard": null,
    "identityType": null,
    "payPwd": null,
    "twoAuth": 1,
    "emailAuth": 0,
    "balance": null,
    "coinRate": {},
    "privacy": 0,
    'selectedCoinType': ''
});
//number1 number2需要处理的两个数 type - add/sub/mul/div
const accuracyNumber = (number) => {
    return Math.round(Number(number) * 100) / 100;
};
//监听游览器窗口变化，获取当前是什么设备
const deviceAdvanced = ref(window.innerWidth < 768 ? 'mobile' : window.innerWidth > 768 && window.innerWidth < 1200 ? 'tablet' : 'pc');
const u = storage.getItem('userInfo');
u ? userInfo.value = { ...userInfo.value, ...JSON.parse(u) } : '';
const changeLoginStatus = (bol, str, isWs301Init) => {
    loginStatus.value = bol;
    Storage.setItem('loginStatus', String(bol));
    if (str === 'init')
        initWebSocket();
    if (bol) {
        //登录以后
        getUserData(isWs301Init);
        if (str !== 'init') {
            disconnectWebSocket();
            reconnectWebSocket();
        } //断开重连
        // if (str !== 'init') initWebSocket()
        bus.emit('getNewsNotify');
    }
    else {
        //退出以后
        if (window.location.hash.indexOf('profile') !== -1) {
            router.push('/');
            userInfo.value = {
                "id": "",
                "userName": "",
                "currentProgress": 0,
                "currentLevelValue": '0',
                "googleSecret": "",
                "nextProgress": "",
                "progress": "",
                "levelId": "",
                "googleUrl": "",
                "selectedBalanceType": "",
                "canGetUpReward": 0,
                "canGetUpRewardAmount": '',
                "currencyType": '',
                "imgUrl": "",
                "tell": null,
                "email": "",
                "sharCode": null,
                "gent": null,
                "nickName": null,
                "birth": null,
                "country": null,
                "province": null,
                "region": null,
                "address": null,
                "identityCard": null,
                "identityType": null,
                "payPwd": null,
                "twoAuth": 1,
                "emailAuth": 0,
                "balance": null,
                "coinRate": {},
                "privacy": 0,
                'selectedCoinType': ''
            };
        }
        Storage.clear();
    }
};
//ws断开，需要去请求余额信息，查看是否有余额变动
const getUserBalance = () => {
    if (!loginStatus.value)
        return;
    userContent.wsInfo('', (res) => {
        userInfo.value.balance = res.data.balance;
        userInfo.value.selectedCoinType = res.data.selectedCoinType;
    });
};
//获取用户数据
const getUserData = (isWs301Init, levelFlag) => {
    // if (!loginStatus.value) return
    // if (userInfo.value) return
    userContent.getMyInfo({ levelFlag: !!levelFlag }, (res) => {
        if (res) {
            userInfo.value = { ...userInfo.value, ...res.data };
            userInfo.value.currentProgress = (parseFloat(((res.data.progress * 1 - res.data.currentLevelValue * 1) / (res.data.nextProgress * 1 - res.data.currentLevelValue * 1)).toFixed(2)) * 100) || 0;
            // userInfo.value = res.data; //直接赋值导致初始对象地址丢失
            userInfo.value.progress = res.data.progress;
            userInfo.value.currentLevelValue = res.data.currentLevelValue;
            userInfo.value.nextProgress = res.data.nextProgress;
            loginStatus.value = true;
            console.log('登录完成', userInfo.value);
            Storage.setItem('loginStatus', String(true));
            Storage.setItem('userInfo', JSON.stringify({ ...userInfo.value, ...res.data }));
            !levelFlag && CygameConfigurationFun(true);
            changeVipLevelProgress();
            setTimeout(() => {
                window.cybetGetUserInfo(true);
            }, 1000);
        }
        else {
            changeLoginStatus(false, 'init');
        }
    });
};
watch(() => userInfo.value.progress, () => {
    changeVipLevelProgress();
});
const changeVipLevelProgress = () => {
    const res = userInfo.value;
    if (Number(res.nextProgress) === Number(res.progress)) {
        VipLevelProgress.value = 100;
        return;
    }
    VipLevelProgress.value = (parseFloat(((Number(res.progress) - Number(res.currentLevelValue)) / (Number(res.nextProgress) - Number(res.currentLevelValue))).toFixed(2)) * 100) || 0;
};
const VipLevelProgress = ref(0);
// 测试环境的游戏打包配置更改内容
/* const operatorToken = "f866HpE634EyC2PTxEMHHBtJZ2ymwtHR";
  const domain = 0
  const hostName = 'h5.hsgame.vip'
*/
const CygameConfigurationFun = (bol) => {
    if (storage.getItem('windowCygameConfiguration')) {
        //保存在session有并且没超过一天
        const time = new Date().getTime();
        const oneDayInMs = 24 * 60 * 60 * 1000; // 一天的毫秒数
        const obj = JSON.parse(storage.getItem('windowCygameConfiguration') || '');
        if (time - obj.time < oneDayInMs) {
            window.CygameConfiguration = function () {
                return storage.getItem('CygameConfiguration');
            };
            window["cygamehttp"] = obj;
            return;
        }
    }
    //如果是true 则直接使用userInfo来，如果是false，则去sessionStrage里面拿
    const userInfoData = bol ? userInfo.value : JSON.parse(Storage.getItem('userInfo'));
    const operatorToken = process.env.NODE_ENV === 'production' ? '5nCG5tTsASs4dK4SX3NYwASAe7WeQt3S' : "f866HpE634EyC2PTxEMHHBtJZ2ymwtHR";
    const operatorPlayerSession = `${userInfoData.id}`;
    const usd = `,${userInfoData.selectedBalanceType},${userInfoData.selectedCoinType}`;
    const gameId = 0;
    const lang = "en";
    const domain = process.env.NODE_ENV === 'production' ? '1' : '0';
    const isMobile = deviceAdvanced.value === 'mobile' ? '0' : '2';
    const hostName = process.env.NODE_ENV === 'production' ? 'h5.hsgame.win' : 'h5.hsgame.vip';
    //发送给游戏端的URL
    const gameUrl = `1?operator_token=${operatorToken}&operator_player_session=${operatorPlayerSession}${usd}&game_id=${gameId}&lang=${lang}&domain=${domain}&ishorizontal=${isMobile}`;
    Storage.setItem('CygameConfiguration', gameUrl);
    window.CygameConfiguration = function () {
        return gameUrl;
    };
    gameServerApi.login({
        operator_token: operatorToken,
        operator_player_session: operatorPlayerSession + ',' + userInfoData.selectedBalanceType + ',' + userInfoData.selectedCoinType,
        game_id: gameId,
        lang: lang,
        domain: domain,
        ishorizontal: isMobile
    }, (res) => {
        if (!res) {
            window["cygamehttp"] = null;
            Storage.removeItem('windowCygameConfiguration');
        }
        else {
            window["cygamehttp"] = {
                token: operatorToken,
                operator_player_session: operatorPlayerSession + ',' + userInfo.value.selectedBalanceType + ',' + userInfo.value.selectedCoinType,
                data: res.data,
            };
            Storage.setItem('windowCygameConfiguration', JSON.stringify({
                token: operatorToken,
                operator_player_session: operatorPlayerSession + ',' + userInfo.value.selectedBalanceType + ',' + userInfo.value.selectedCoinType,
                data: res.data,
                time: new Date().getTime()
            }));
        }
    });
    // const apiUrl = `https://${hostName}/api/web-lobby/login?operator_token=${operatorToken}&operator_player_session=${operatorPlayerSession}${usd}&game_id=${gameId}&lang=${lang}&ishorizontal=${isMobile}`; //本地 0
    // console.log('apiUrl', apiUrl, '---', deviceAdvanced.value, window.innerWidth)
    // fetch(apiUrl)
    //     .then(res => {
    //         if (!res.ok) {
    //             throw new Error('网络响应不正常');
    //         }
    //         return res.json(); // 将响应体转换为 JSON
    //     })
    //     .then(data => {
    //         if (data.error == null) {
    //             console.log('userInfo--------', data)
    //             window["cygamehttp"] = {
    //                 token: operatorToken,
    //                 operator_player_session: operatorPlayerSession + ',' + userInfo.value.selectedBalanceType + ',' + userInfo.value.selectedCoinType,
    //                 data: data.data,
    //             };
    //         } else {
    //             window["cygamehttp"] = null
    //         }
    //     })
    //     .catch(error => {
    //         console.error('获取数据时出错:', error); // 捕获和处理错误
    //     });
};
// if (storage.getItem('windowCygameConfiguration') && window.location.href.indexOf('/game/')) {
//     //如果内存里面有userInfo并且在游戏页面刷新
//     CygameConfigurationFun(false)
// }
const coinType = ref([]);
const legalType = ref([]);
const getCurreny = async () => {
    await wallet.getCurreny({}, (res) => {
        if (!res)
            return;
        coinType.value = res.data.coins;
        legalType.value = res.data.faits;
    });
};
const rateData = ref({}); //虚拟币与法币之间的汇率
const legalData = ref({}); //法币与法币之间的汇率
const getRate = () => {
    wallet.rateAll({}, (res) => {
        if (!res)
            return;
        rateData.value = res.data.rate;
        legalData.value = res.data.legalRate.results;
        setTimeout(() => {
            window.cybetGetUserInfo(true);
        }, 1000);
    });
};
//获取当前法币的图标和symbol
const getDefailtImg = computed(() => {
    if (userInfo.value.selectedCoinType && coinType.value.length > 0) {
        const obj = coinType.value.find((item) => item.coin === userInfo.value.selectedCoinType);
        if (obj) {
            return obj.imgUrl || require("@/assets/img/monyimg/1.svg");
        }
        return require("@/assets/img/monyimg/1.svg");
    }
    return require("@/assets/img/monyimg/1.svg");
});
const getDefailtSymbol = computed(() => {
    if (userInfo.value.currencyType === 'COIN')
        return '';
    if (userInfo.value.selectedBalanceType && legalType.value.length > 0) {
        const obj = legalType.value.find((item) => item.fiat === userInfo.value.selectedBalanceType);
        if (obj) {
            if (!loginStatus.value) {
                return '$';
            }
            return obj.symbol;
        }
        return '$';
    }
    return '$';
});
const victuralList = ref([]);
// //获取当前能使用的虚拟币
// function getVictural(): Promise<void> {
//     return new Promise((resolve) => {
//         const arr: any = []
//         coinType.value.forEach((item: any) => {
//             const obj = item
//             obj.amount = 0.00
//             Object.keys(userInfo.value.balance).forEach((it: any) => {
//                 if (it === item.coin) {
//                     obj.amount = userInfo.value.balance[it]
//
//                 }
//             })
//             arr.push(obj)
//         })
//         victuralList.value = arr
//         resolve()
//     });
// }
//获取当前能使用的虚拟币
function getVictural() {
    return new Promise((resolve) => {
        const arr = [];
        coinType.value.forEach((item) => {
            const obj = item;
            obj.amount = 0.00;
            Object.keys(userInfo.value.balance).forEach((it) => {
                if (it === item.coin) {
                    obj.amount = userInfo.value.balance[it];
                }
            });
            arr.push(obj);
        });
        victuralList.value = arr;
        resolve();
    });
}
//获取游戏分类
const centerHeaderNavList = ref([]);
//当前选中的一级游戏菜单的ID
const centerActive = ref(Storage.getItem('centerActive') || '');
loginStatus.value = Storage.getItem('loginStatus') === 'true';
const liveStatus = ref(Storage.getItem('liveStatus') !== 'false');
//切换聊天室伸缩
const changeLiveStatus = () => {
    const bol = Storage.getItem('liveStatus') !== 'true';
    liveStatus.value = Storage.getItem('liveStatus') === null ? false : bol;
    Storage.setItem('liveStatus', bol);
};
//未读的通知数量
const unReadNumber = ref(0);
//监听游览器窗口变化，获取当前main的视口宽邸
const mainViewportWidth = ref(1094);
//汇率转换
const newgetLegalTenderToRate = (key) => {
    const uD = userInfo.value;
    if (!uD || !uD.balance || !uD.balance[key])
        return '0.00';
    if (!uD || !rateData.value || !rateData.value[key])
        return '0.00';
    const d = uD.balance[key];
    const legalIcon = uD.selectedBalanceType;
    const r = rateData.value[key][legalIcon];
    if (!legalIcon || !r)
        return formatPrice(d.amount);
    return formatPrice(d.amount * r.price);
};
/**
 *
 * @param amount 金额
 * @param victuralCoin 虚拟币币种
 * @param legalCoin 法币币种 默认为userInfo设置的
 * @param checkCY 是否需要检测法币开启，不检测的话，将传入值默认获取虚拟币
 */
// 新版本关于金额转换
const newVictural = (amount, victuralCoin = 'USDT', legalCoin, checkCY) => {
    if (!amount) {
        amount = 0.00;
    }
    const rate = rateData.value[victuralCoin];
    if (userInfo.value.currencyType === 'COIN' && !checkCY) {
        return formatPrice(Number(amount));
    } //如果是法币关闭
    if (Object.values(rateData.value).length === 0) {
        //表示当前还没有获取到
        return formatPrice(amount);
    }
    if (!legalCoin)
        legalCoin = userInfo.value.selectedBalanceType;
    if (!rate)
        return formatPrice(Number(amount));
    const legalRate = rate[legalCoin] || 0;
    if (!rate || !legalRate)
        return formatPrice(Number(amount));
    if (userInfo.value.currencyType === 'FAIT' || checkCY) //如果是法币开启 或者 需要虚拟币值
        return formatPrice(Number(amount) * legalRate.price);
    else //如果是法币关闭
        return formatPrice(Number(amount));
};
// 虚拟币转法币
/**
 *
 * @param amount 金额
 * @param victuralCoin currency 虚拟币种
 * @param coinToFait rate 法币之间的汇率
 * @param legalCoin 法币类型
 * @param fanRate
 */
const newVictural1111 = (amount, victuralCoin = 'USDT', coinToFait, legalCoin, fanRate) => {
    if (!amount) {
        amount = 0.00;
    }
    // 未登录的红包和滚动的win:传入虚拟币,显示法币
    if (!loginStatus.value) {
        if (typeof (coinToFait) !== "string") {
            if (fanRate) {
                // console.warn('未登录',coinToFait["USD"]);
                return formatPrice(decimal_div(amount, coinToFait["USD"]));
            }
            else {
                return formatPrice(decimal_mul(amount, coinToFait["USD"]));
            }
        }
        else if (typeof (coinToFait) === "string") {
            if (fanRate) {
                // console.warn('未登录',coinToFait["USD"]);
                return formatPrice(decimal_div(amount, coinToFait));
            }
            else {
                return formatPrice(decimal_mul(amount, coinToFait));
            }
        }
    }
    if (userInfo.value.currencyType === 'COIN') {
        return formatPrice(amount); //如果是法币关闭
    }
    if (!legalCoin) {
        legalCoin = userInfo.value.selectedCoinType;
    }
    else if (!loginStatus.value) {
        legalCoin = 'USD';
    }
    if (!coinToFait)
        return formatPrice(amount);
    const rate = ref();
    if (typeof (coinToFait) == "string") {
        rate.value = Number(coinToFait);
    }
    else {
        rate.value = Number(coinToFait[legalCoin]);
        if (!rate.value) {
            return formatPrice(amount);
        }
    }
    if (userInfo.value.currencyType === 'FAIT' || !userInfo.value.currencyType) //如果是法币开启
        if (fanRate) {
            return formatPrice(decimal_div(amount, rate.value));
        }
        else {
            return formatPrice(decimal_mul(amount, rate.value));
        }
    else {
        return formatPrice(amount);
    } //如果是法币关闭    
};
//虚拟币转法币
const victuralToLegal = (amount, victuralCoin = 'USDT', legalCoin = 'USD') => {
    if (!amount) {
        amount = 0.00;
    }
    if (Object.values(rateData.value).length === 0) {
        //表示当前还没有获取到
        return formatPrice(amount);
    }
    const rate = rateData.value[victuralCoin];
    if (!rate)
        return formatPrice(amount);
    const legalRate = rate[legalCoin];
    if (!rate || !legalRate)
        return formatPrice(amount);
    if (userInfo.value.currencyType === 'FAIT')
        return formatPrice(Number(amount) * legalRate.price);
    else
        return formatPrice(Number(amount) * legalRate.inversePrice);
};
/**
 * 判断传入金额是否大于0.01USD
 * 区分虚拟币和法币
 * @param number
 */
const isGreatThan = (number) => {
    const getRate = () => {
        if (loginStatus.value) {
            //已经登录
            return legalData.value[userInfo.value.selectedBalanceType];
        }
        else {
            return 1;
        }
    };
    if (!number)
        return true;
    if (userInfo.value.currencyType === 'FAIT') {
        const rateNum = Number(newVictural1111(number, '', getRate(), userInfo.value.selectedBalanceType, true));
        if (rateNum < 0.01) {
            //当前法币小于0.01USD
            console.log('当前法币是', userInfo.value.selectedBalanceType, '汇率是', getRate(), '兑换USD的金额是', rateNum);
            return true;
        }
        else {
            return false;
        }
    }
    else {
        if (Number(newVictural(number, userInfo.value.selectedCoinType, 'USD', true)) < 0.01) {
            console.log('当前虚拟币是', userInfo.value.selectedCoinType, Number(newVictural(number, userInfo.value.selectedCoinType, 'USD', true)));
            return true;
        }
        else {
            return false;
        }
    }
};
//法币转usd法币
const legalTenderToLegal = (data, bol) => {
    let num = '0';
    const legal = ref('USD');
    if (userInfo.value.currencyType === 'COIN') {
        // legal.value = "USD";
        num = formatPoint(Number(data), 2);
    }
    else {
        legal.value = userInfo.value.selectedBalanceType || "USD"; //法币
        const amount = legalData.value[legal.value] || ""; //法币之间的汇率
        if (amount) {
            // num = formatPoint(Number(data)/Number(amount),2);
            if (bol) {
                num = formatPoint(decimal_mul(data, amount), 2);
            }
            else {
                num = formatPoint(decimal_div(data, amount), 2);
            }
        }
    }
    return num || '0.00';
};
const toPlayGame = (e) => {
    const data = {
        gameCode: e.gameCode,
        channel: e.channel,
        gameName: e.name,
        id: e.id,
        imgUrl: e.imgUrl,
        currency: userInfo.value.selectedBalanceType || "USD",
    };
    Storage.setItem('currentGame', JSON.stringify(data));
    if (data.channel === 'HS-GAME') {
        router.push({ name: e.gameCode, params: { id: data.id } });
    }
    else {
        router.push({ name: "playGame", query: data });
    }
};
const userInfoCurrencyTypeisFait = computed(() => {
    return userInfo.value.currencyType === 'FAIT';
});
export { loginStatus, deviceAdvanced, mainViewportWidth, getUserBalance, toPlayGame, newgetLegalTenderToRate, userInfoCurrencyTypeisFait, getUserData, coinType, unReadNumber, userInfo, changeLoginStatus, changeLiveStatus, liveStatus, centerHeaderNavList, centerActive, legalType, getCurreny, getRate, rateData, legalData, newVictural, newVictural1111, VipLevelProgress, victuralToLegal, getVictural, accuracyNumber, isGreatThan, victuralList, legalTenderToLegal, getDefailtImg, getDefailtSymbol, };
