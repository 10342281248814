import { reactive, ref, watch } from "vue";
import { loginStatus } from "@/Utils/hook/hook";
import nprogress from "nprogress";
import "nprogress/nprogress.css";
//是否允许播放点击音效
const soundState = reactive({
    isClickSoundEnabled: false, // 控制电机btn是否允许播放音效
});
//是否开启快速
const isFastGame = ref(false);
// 游戏是否加载完成
const gameLoadingOver = ref(false);
watch(() => soundState.isClickSoundEnabled, () => {
    playSound();
});
const playSound = () => {
    window.postMessage(JSON.stringify({ type: "carshsound", isopen: soundState.isClickSoundEnabled }), "*");
    // (window as any).postMessage(JSON.stringify({type: "dicesound", isopen: soundState.isClickSoundEnabled}), "*");
    window.postMessage(JSON.stringify({ type: 'limbosound', isopen: soundState.isClickSoundEnabled }), '*');
    // (window as any).postMessage(JSON.stringify({type: 'minessound', isopen: soundState.isClickSoundEnabled}), '*');
    window.postMessage(JSON.stringify({ type: 'plinkosound', isopen: soundState.isClickSoundEnabled }), '*');
};
const cygGameConfig = ref(null);
watch(() => loginStatus.value, () => {
    if (!loginStatus.value)
        cygGameConfig.value = null;
});
const gameOnline = ref(true);
const gameOffline = ref(false);
window.addEventListener("online", () => {
    console.log("网络恢复");
    gameOnline.value = true;
    gameOffline.value = false;
    nprogress.done();
});
window.addEventListener("offline", () => {
    console.log("网络断开");
    gameOnline.value = false;
    gameOffline.value = true;
    nprogress.start();
});
export { soundState, cygGameConfig, gameOnline, gameOffline, isFastGame, gameLoadingOver, playSound };
