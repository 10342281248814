export default {
    main: {
        login: 'ورود',
        signup: 'ثبت‌نام',
        language: 'زبان',
        toTop: 'به بالا',
        licence: 'مجوز',
        profile: 'پروفایل شخصی',
        nodata: 'بدون داده',
        logout: 'خروج',
        notify: 'اعلان‌ها',
        all: 'همه',
        system: 'اعلان سیستم',
        activity: 'فعالیت‌ها',
        clear: 'پاک کردن همه',
        more: 'بارگذاری بیشتر',
        wallet: 'کیف پول',
        deposit: 'واریز',
        withdraw: 'برداشت',
        trans: 'تراکنش‌ها',
        currency: 'ارز واریز',
        network: 'شبکه',
        address: 'آدرس واریز',
        mini: 'حداقل واریز',
        send: 'فقط USDT را به این آدرس واریز کنید. سکه‌ها پس از ۶ تأییدیه شبکه به صورت خودکار واریز می‌شوند. آدرس‌های قرارداد هوشمند پشتیبانی نمی‌شوند (تماس بگیرید).',
        withAddress: 'آدرس برداشت',
        withEnter: 'آدرس خود را وارد کنید',
        tag: 'برچسب',
        tagEnter: 'لطفا وارد شوید',
        amount: 'مبلغ برداشت',
        security: 'به دلایل امنیتی، برداشت‌های بزرگ یا مشکوک ممکن است ۱ تا ۶ ساعت برای فرآیند بررسی زمان ببرد. از شکیبایی شما سپاسگزاریم!',
        today: 'امروز',
        week: 'این هفته',
        month: 'این ماه',
        year: 'امسال',
        myWallet: 'کیف پول من',
        available: 'موجودی',
        fee: 'کارمزد',
        chat: 'چت',
        online: 'آنلاین',
        message: 'پیام خود را وارد کنید',
        rules: 'قوانین'
    },
    login: {
        email: "لطفاً ایمیل را وارد کنید",
        incorrect: "فرمت ایمیل نادرست است",
        password: "لطفاً گذرواژه را وارد کنید",
        characters: 'رمز عبور بین 6 تا 15 رقم یا حرف طول می کشد.',
        account: "حساب",
        bindAccount: 'حساب بندی',
        login: "ورود",
        register: "ثبت نام",
        userEmail: "ایمیل شما",
        enterEmail: "ایمیل خود را وارد کنید",
        userPas: "رمز عبور شما",
        enterPas: "رمز عبور خود را وارد کنید",
        forget: "رمز عبور را فراموش کرده‌اید؟",
        or: "یا",
        agree: "من شرایط و ضوابط را مطالعه کرده و موافقت می‌کنم.",
        resAndLogin: "ثبت نام و ورود",
        policy: 'این سایت توسط reCAPTCHA محافظت می‌شود و سیاست حریم خصوصی و شرایط خدمات Google اعمال می‌شود.'
    },
    profile: {
        password: "لطفاً گذرواژه جدید را وارد کنید",
        long: "گذرواژه باید حداقل یک عدد، حرف یا فضای خالی داشته باشد و طول آن بین 6 تا 15 کاراکتر باشد",
        newPas: "گذرواژه تاییدی با گذرواژه جدید مطابقت ندارد",
        original: "لطفاً گذرواژه اصلی را وارد کنید",
        personal: 'تنظیمات شخصی',
        change: 'تغییر نام مستعار و داده‌های شما',
        username: 'نام کاربری شما',
        hide: 'مخفی کردن نام مستعار من',
        private: 'پروفایل خصوصی',
        closing: 'بستن حساب شما',
        closeDatail: 'شما می‌توانید با کلیک بر روی دکمه "بستن حساب من" در زیر، حساب خود را ببندید. تمام داده‌های شما به طور دائمی حذف خواهند شد و امکان بازیابی آن در آینده وجود ندارد (شامل هر گونه مزایای VIP).',
        closeMy: 'بستن حساب من',
        social: 'اجتماعی',
        media: 'اتصال به شبکه‌های اجتماعی',
        connected: 'متصل شده',
        noConnect: 'متصل نشده',
        settings: 'تنظیمات',
        account: 'تنظیمات عمومی حساب شما',
        email: 'ایمیل',
        verify: 'لطفا ایمیل خود را تایید کنید',
        factor: 'احراز هویت دو مرحله‌ای',
        enable: 'احراز هویت دو مرحله‌ای را فعال کنید تا حساب شما از دسترسی غیرمجاز محافظت شود.',
        prohibit: 'ممنوع کردن',
        pas: 'رمز عبور',
        current: 'رمز عبور فعلی شما',
        please: 'لطفا رمز عبور فعلی خود را وارد کنید',
        newPassword: 'رمز عبور جدید',
        paleseNew: 'لطفا رمز عبور جدید خود را وارد کنید',
        repeat: 'تکرار رمز عبور',
        pleaseCurrent: 'لطفا رمز عبور فعلی خود را وارد کنید',
        changePas: 'تغییر رمز عبور',
        sessions: 'جلسات',
        devices: 'دستگاه‌های خود را بررسی کنید',
        history: 'تاریخچه بازی',
        referrals: 'ارجاع‌ها',
        VIP: 'برنامه VIP',
        check: 'تمام تراکنش‌های خود را بررسی کنید',
        allTrans: 'تمام تراکنش‌ها',
        withdrawal: 'برداشت‌ها',
        timeframe: 'بازه زمانی',
        today: 'امروز',
        deposits: 'سپرده‌ها',
        chackGame: 'تمام بازی‌های خود را بررسی کنید',
        chain: 'زنجیره'
    },
    components: {
        search: "جستجو",
        sort: "مرتب‌سازی بر اساس:",
        popular: "محبوب",
        providers: "ارائه‌دهندگان:",
        new: "جدید",
        hot: "داغ",
        notice: "هنوز اطلاعیه‌ای وجود ندارد.",
        enable: "فعال کردن",
        cybet: "Cybet.com یک نشان تجاری متعلق به Raw Entertainment B.V. است، شماره ثبت 157205، ثبت شده در Korporaalweg 10، کوراسائو، با مجوز برای بازی‌های آنلاین از دولت کوراسائو، شماره مجوز 8048/JAZ. Raw Entertainment Ltd، شماره ثبت HE421735، ثبت شده در Voukourestiou، 25، Neptune House، طبقه اول، آپارتمان/دفتر 11، Zakaki، 3045، لیماسول، قبرس، عضو گروه Cybet است و به عنوان نماینده پرداخت و عملیاتی به نمایندگی از Raw Entertainment B.V. عمل می‌کند. (18+).",
        progress: "پیشرفت VIP شما",
        discover: "با سیستم انحصاری VIP CY.GAMES تجربه نهایی بازی را کشف کنید!",
        link: "لینک شما",
        share: "به‌ازای دعوت از هر دوست، 1000 دلار به‌اضافه کمیسیونی تا 25% از شرط‌بندی‌های آن‌ها دریافت کنید. از کمیسیون‌های پایدار لذت ببرید، چه برنده شوند چه بازنده، در کازینو و ورزشگاه ما. همین حالا شروع به کسب درآمد کنید!",
        referralLink: "لینک معرفی شما",
        code: "کد معرفی شما",
        yourReferrals: "تمامی معرفی‌های شما",
        receive: "دریافت",
        game: "تمام بازی‌ها",
        winnings: "برد",
        loosing: "باخت",
        wagered: "بازیکنان شما شرط‌بندی کرده‌اند",
        earned: "شما کسب کرده‌اید",
        nothing: "هنوز چیزی برای مطالبه وجود ندارد",
        join: "به جامعه بازیکنان نخبه ما بپیوندید و از بهترین تجربه بازی‌های آنلاین لذت ببرید.",
        details: "جزئیات",
        unverified: "تایید نشده",
        verified: "ایمیل تایید شده است",
        type: "نوع تراکنش",
        date: "تاریخ",
        total: "مجموع شرط بندی شده",
        methods: "روش های پرداخت",
        status: "وضعیت",
        device: "دستگاه",
        location: "موقعیت",
        IP: "آدرس IP",
        last: "آخرین استفاده",
        tableGame: "بازی",
        bet: "شرط",
        multiplayer: "چند نفره",
        profit: "سود",
        searchGame: "جستجوی بازی",
        provider: "ارائه دهندگان",
        ourGames: "بازی های ما",
        allWins: "تمام پیروزی ها",
        myBets: 'شرط هام',
        luckyWins: "پیروزی های خوش شانس",
        bigWins: "پیروزی های بزرگ",
        latest: "آخرین شرط ها",
        player: "نام بازیکن",
        time: "زمان",
        amount: "مقدار",
        coefficent: "ضریب"
    },
    wallet: {
        noAddress: 'آدرس نمی‌تواند خالی باشد',
        submit: 'درخواست برداشت ارسال شده است، در انتظار تأیید...',
        range: 'لطفاً مقدار عددی در محدوده برداشت وارد کنید'
    }
};
