export default {
    main: {
        login: 'Entrar',
        signup: 'Inscrever-se',
        language: 'Idioma',
        toTop: 'Ir para o topo',
        licence: 'Licença',
        profile: 'Perfil Pessoal',
        nodata: 'Sem dados',
        logout: 'Sair',
        notify: 'Notificações',
        all: 'Todos',
        system: 'Aviso do sistema',
        activity: 'Atividades',
        clear: 'Limpar tudo',
        more: 'Carregar mais',
        wallet: 'Carteira',
        deposit: 'Depósito',
        withdraw: 'Retirada',
        trans: 'Transações',
        currency: 'Moeda de depósito',
        network: 'Rede',
        address: 'Endereço de depósito',
        mini: 'Depósito mínimo',
        send: 'Envie apenas USDT para este endereço de depósito. As moedas serão depositadas automaticamente após 6 confirmações da rede. Endereços de contratos inteligentes não são suportados (Contacte-nos).',
        withAddress: 'Endereço de retirada',
        withEnter: 'Digite seu endereço',
        tag: 'tag',
        tagEnter: 'Por favor, introduza',
        amount: 'Quantia de retirada',
        security: 'Por motivos de segurança, retiradas grandes ou suspeitas podem levar de 1 a 6 horas para o processo de auditoria. Agradecemos sua paciência!',
        today: 'Hoje',
        week: 'Esta semana',
        month: 'Este mês',
        year: 'Este ano',
        myWallet: 'Minha carteira',
        available: 'Disponível',
        fee: 'Taxa',
        chat: 'Bate-papo',
        online: 'Online',
        message: 'Digite a mensagem',
        rules: 'Regras'
    },
    login: {
        email: "Por favor, insira o e-mail",
        incorrect: "O formato do e-mail está incorreto",
        password: "Por favor, insira a senha",
        characters: "A palavra-passe tem de ter entre 6 e 15 dígitos ou letras.",
        account: "Conta",
        bindAccount: 'Conta Vinculada',
        login: "Entrar",
        register: "Registrar",
        userEmail: "Seu e-mail",
        enterEmail: "Digite seu e-mail",
        userPas: "Sua senha",
        enterPas: "Digite sua senha",
        forget: "Esqueceu a senha?",
        or: "ou",
        agree: "Li e concordo com os Termos e Condições.",
        resAndLogin: "Registrar e entrar",
        policy: "Este site está protegido pelo reCAPTCHA e a Política de Privacidade e os Termos de Serviço do Google aplicam-se."
    },
    profile: {
        password: "Por favor, insira uma nova senha",
        long: "A senha deve conter pelo menos um dígito, letra ou espaço em branco e ter entre 6-15 caracteres",
        newPas: "A senha de confirmação não corresponde à nova senha",
        original: "Por favor, insira a senha original",
        personal: 'Configurações Pessoais',
        change: 'Alterar apelido e seus dados',
        username: 'Seu nome de usuário',
        hide: 'Ocultar meu apelido',
        private: 'Perfil privado',
        closing: 'Fechar sua conta',
        closeDatail: 'Você pode fechar sua conta clicando no botão "Fechar Minha Conta" abaixo. Todos os seus dados serão permanentemente excluídos sem possibilidade de recuperação futura (incluindo quaisquer benefícios VIP).',
        closeMy: 'Fechar minha conta',
        social: 'Social',
        media: 'Conectar mídias sociais',
        connected: 'Conectado',
        noConnect: 'não conectado',
        settings: 'Configurações',
        account: 'Configurações gerais da sua conta',
        email: 'E-mail',
        verify: 'Por favor, verifique seu e-mail',
        factor: 'Autenticação de dois fatores',
        enable: 'Ative a autenticação de dois fatores para proteger sua conta contra acessos não autorizados.',
        prohibit: 'Proibir',
        pas: 'Senha',
        current: 'Sua senha atual',
        please: 'Por favor, insira sua senha atual',
        newPassword: 'Nova senha',
        paleseNew: 'Por favor, insira sua nova senha',
        repeat: 'Repetir senha',
        pleaseCurrent: 'Por favor, insira sua senha atual',
        changePas: 'Alterar senha',
        sessions: 'Sessões',
        devices: 'Verifique seus dispositivos',
        history: 'Histórico de jogos',
        referrals: 'Indicações',
        VIP: 'Programa VIP',
        check: 'Verifique todas as suas transações',
        allTrans: 'Todas as transações',
        withdrawal: 'Saques',
        timeframe: 'Período',
        today: 'Hoje',
        deposits: 'Depósitos',
        chackGame: 'Verifique todos os seus jogos',
        chain: 'Cadeia'
    },
    components: {
        search: "Pesquisar",
        sort: "Ordenar por:",
        popular: "Popular",
        providers: "Provedores:",
        new: "Novo",
        hot: "Quente",
        notice: "Ainda não há notificações.",
        enable: "Ativar",
        cybet: "Cybet.com é uma marca registrada da Raw Entertainment B.V., número de registro 157205, registrada em Korporaalweg 10, Curaçau, licenciada para jogos online pelo governo de Curaçau, número de licença 8048/JAZ. Raw Entertainment Ltd, número de registro HE421735, registrada em Voukourestiou, 25, Neptune House, 1º andar, apartamento/escritório 11, Zakaki, 3045, Limassol, Chipre, é membro do grupo Cybet e atua como agente de pagamento e operacional em nome da Raw Entertainment B.V. (18+).",
        progress: "Seu progresso VIP",
        discover: "Descubra a experiência de jogo definitiva com o exclusivo sistema VIP da CY.GAMES!",
        link: "Seu link",
        share: "Ganhe $1.000,00 para cada amigo que você convidar, além de até 25% de comissão nas apostas deles. Aproveite as comissões constantes, quer eles ganhem ou percam, no nosso cassino e na casa de apostas. Comece a ganhar agora!",
        referralLink: "Seu link de indicação",
        code: "Seu código de indicação",
        yourReferrals: "Todos os seus indicados",
        receive: "Receber",
        game: "Todos os jogos",
        winnings: "Ganhos",
        loosing: "Perdas",
        wagered: "Seus jogadores apostaram",
        earned: "Você ganhou",
        nothing: "Nada a reivindicar ainda",
        join: "Junte-se à nossa comunidade de jogadores de elite e aproveite o melhor que o jogo online tem a oferecer.",
        details: "Detalhes",
        unverified: "não verificado",
        verified: "E-mail verificado",
        type: "Tipo de transação",
        date: "Data",
        total: "Total apostado",
        methods: "Métodos de pagamento",
        status: "Status",
        device: "Dispositivo",
        location: "Localização",
        IP: "Endereço IP",
        last: "Último uso",
        tableGame: "Jogo",
        bet: "Aposta",
        multiplayer: "Multijogador",
        profit: "Lucro",
        searchGame: "Pesquisar jogo",
        provider: "Provedores",
        ourGames: "Nossos jogos",
        myBets: 'As minhas apostas',
        allWins: "Todas as vitórias",
        luckyWins: "Vitórias felizes",
        bigWins: "Grandes vitórias",
        latest: "Últimas apostas",
        player: "NOME DO JOGADOR",
        time: "HORA",
        amount: "VALOR",
        coefficent: "COEFICIENTE"
    },
    wallet: {
        noAdress: 'O endereço não pode estar vazio',
        submit: 'A solicitação de retirada foi enviada, aguardando confirmação...',
        range: 'Por favor, insira um valor dentro da faixa de retirada'
    }
};
