import axios from "axios";
import Storage from '@/Utils/ts/storage';
import { changeLoginStatus } from '../hook/hook';
// import {bus} from '@/Utils/bus'
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import MessageService from '@/plugins/message-plugin';
const baseUrl = process.env.NODE_ENV === 'development' ? '/ServerApi' : 'https://' + window.location.host;
const gameUrl = process.env.NODE_ENV === 'production' ? 'https://h5.hsgame.win' : 'https://h5.hsgame.vip';
// const gameUrl = 'https://h5.hsgame.win'
const code = [501, 500, 401, 404, 403];
const objectToQueryString = (obj) => {
    return Object.keys(obj).map(key => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(String(obj[key])).replace(/%2C/g, ',')}`;
    }).join('&');
};
const getHeader = (isUpload) => {
    if (isUpload) {
        return 'application/x.svg-www-form-urlencoded';
    }
    else {
        return 'application/json';
    }
};
const getUrl = (url, type, sendData, isGame) => {
    let str = (isGame ? gameUrl : baseUrl) + url;
    if (type === 'GET' && sendData) {
        str = (isGame ? gameUrl : baseUrl) + url + '?' + objectToQueryString(sendData);
    }
    return str;
};
let ElMessageError = false;
const AxiosServer = (url, sendData, callback, type, isUpload, isGame) => axios({
    method: type ? type : 'POST',
    url: getUrl(url, type, sendData, isGame),
    data: sendData,
    headers: {
        'Content-Type': getHeader(isUpload || false),
        // 'lang': Storage.getItem('language') || 'en_US'
        'lang': 'en_US'
        // 'lang': 'zh_CN'
    },
    timeout: 50000
}).then(function (response) {
    if (code.indexOf(response.code) === -1 || url === '/v1/account/withdraw') {
        return callback(response);
    }
    else if (response.code === 401) {
        // alert('登录失效')
        changeLoginStatus(false);
        Storage.clear();
        if (url !== '/v1/user/info' && url !== '/v1/user/getMyInfo') {
            //获取用户信息 不需要验证是否登录做跳转
            // bus.emit("openGlobalDialog", { type: "Login" });
            if (!ElMessageError) {
                ElMessageError = true;
            }
        }
        return callback(null);
    }
    else {
        // ElMessage.error(response.message)
        MessageService.error(response.message);
        //后端返回code出错时,获取回调函数值时需要判断是否为null再进行下一步操作
        return callback(null);
    }
});
//请求拦截器----在项目中发请求（请求没有发出去）可以做一些事情
axios.interceptors.request.use((config) => {
    //现在的问题是config是什么?配置对象
    //可以让进度条开始动
    nprogress.start();
    return config;
});
axios.interceptors.response.use(function (response) {
    nprogress.done();
    //响应码拦截
    if (response.data.code != 0) {
        // window.$message.error(response.data.description);
    }
    //  无权限
    if (response.data.code == 40005) {
        //无权限请先设置权限
    }
    return response.data;
}, function (err) {
    if (err) {
        // store.state.loading = false;
    }
    if (err && err.response) {
        switch (err.response.status) {
            case 400:
                err.message = err.response.data.message;
                break;
            case 401:
                err.message = '未授权，请登录';
                break;
            case 403:
                err.message = '拒绝访问';
                break;
            case 404:
                err.message = `请求地址出错: ${err.response.config.url}`;
                break;
            case 408:
                err.message = '请求超时';
                break;
            case 500:
                err.message = '服务器内部错误';
                break;
            case 501:
                err.message = '服务未实现';
                break;
            case 502:
                err.message = '网关错误';
                break;
            case 503:
                err.message = '服务不可用';
                break;
            case 504:
                err.message = '网关超时';
                break;
            case 505:
                err.message = 'HTTP版本不受支持';
                break;
            default:
        }
    }
    return Promise.reject(err);
});
export { AxiosServer };
