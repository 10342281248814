export function formatPricePoint(num, pointNum, cancel) {
    // 转换为字符串，避免直接操作数字导致精度问题
    if (num === 0 || num === '0' || !num)
        return "0.00"; // 若为0则直接返回0.00
    const numStr = num.toString();
    // 检查是否是整数  
    if (numStr.indexOf('.') === -1) {
        // 如果是整数，直接返回  
        return numStr;
    }
    else {
        // 有小数部分，但需要截取到pointNum位
        const parts = numStr.split('.');
        const integerPart = parts[0];
        let decimalPart = parts[1];
        // 判断小数末尾是否存在连续性的0,若存在则去掉且只保留六位小数 
        if (cancel) {
            decimalPart = decimalPart.replace(/0+$/, '');
            if (decimalPart.length > pointNum) {
                decimalPart = decimalPart.slice(0, pointNum);
            }
            else {
                // 若不存在且小数位数大于pointNum，则截取到pointNum位  
                if (decimalPart.length > pointNum) {
                    decimalPart = decimalPart.slice(0, pointNum);
                }
            }
            // 重新组合并返回  
            return decimalPart ? integerPart + '.' + decimalPart : integerPart;
        }
    }
}
// 保留小数位数
export const formatPoint = (num, decimalLength) => {
    // 如果输入为空字符串，则返回 undefined
    if (num === '') {
        return;
    }
    const numStr = num.toString();
    const decimalIndex = numStr.indexOf('.');
    // 如果没有小数点，直接返回整数部分加上指定的小数位数的零
    if (decimalIndex === -1) {
        return decimalLength > 0 ? `${numStr}.${'0'.repeat(decimalLength)}` : numStr;
    }
    const integerPart = numStr.slice(0, decimalIndex);
    let decimalPart = numStr.slice(decimalIndex + 1);
    // 如果小数部分长度不足，进行填充
    if (decimalPart.length < decimalLength) {
        decimalPart = decimalPart.padEnd(decimalLength, '0');
    }
    else if (decimalPart.length > decimalLength) {
        // 如果小数部分长度超过，则截取到指定长度
        decimalPart = decimalPart.slice(0, decimalLength);
    }
    return `${integerPart}.${decimalPart}`;
};
// 与虚拟币法币开关按钮无关:2~6位小数
export function formatPriceNew(num, endNumber) {
    // 转换为字符串，避免直接操作数字导致精度问题
    if (num === 0 || num === '0' || !num)
        return "0.00";
    const numStr = num.toString();
    // 检查是否已经是一个数字加小数点加两位小数的格式
    if (numStr.indexOf('.') === -1) {
        // 整数，直接添加.00
        return numStr + '.00';
        return numStr;
    }
    else {
        const number = endNumber ? endNumber : 2;
        // 有小数部分，但需要截取到两位
        const parts = numStr.split('.');
        const integerPart = parts[0];
        if (!integerPart)
            return '0.00';
        let decimalPart = parts[1];
        // 截取小数部分到两位
        decimalPart = decimalPart.slice(0, number);
        // 如果小数部分不足两位，则补0
        // while (decimalPart.length < 2) {
        //     decimalPart += '0';
        // }
        // 小数位全是0,截取两位
        if (!Number(decimalPart)) {
            decimalPart = '00';
            return integerPart + '.' + decimalPart;
        }
        else {
            // 如果小数部分为6位，则去除小数位多余的0
            if (decimalPart.length > 2) {
                return removeTrailingZeros(integerPart + '.' + decimalPart);
            }
            else {
                if (decimalPart.length === 1) {
                    decimalPart += '0';
                }
                return integerPart + '.' + decimalPart;
            }
        }
        // 重新组合并返回
        // return integerPart + '.' + decimalPart;
    }
}
// 去除6位小数位(虚拟币)多余的0
function removeTrailingZeros(number) {
    // 转换为字符串以便进行操作
    let numberString = number.toString();
    // 使用正则表达式去掉小数末尾的零
    numberString = numberString.replace(/(?:\.0*|(\.\d+?)0+)$/, '$1');
    // 转换回数字类型
    return parseFloat(numberString);
}
