import { AxiosServer } from '@/Utils/ts/axios';
const login = {
    //登录
    loginApi: (val, callback) => {
        AxiosServer('/v1/user/login', val, callback, '').then();
    },
    // 谷歌第三方登录
    loginGoogle: (val, callback) => {
        AxiosServer('/v1/user/thirdLogin/google/login', val, callback, '').then();
    },
    // twitch:第三方登录
    loginTwitch: (val, callback) => {
        AxiosServer('/v1/user/thirdLogin/twitch/login', val, callback, '').then();
    },
    // Facebook:第三方登录
    loginFacebook: (val, callback) => {
        AxiosServer('/v1/user/thirdLogin/oauth2/FacebookAuth', val, callback, 'GET').then();
    },
    //注册
    signupApi: (val, callback) => {
        AxiosServer('/v1/user/register', val, callback, '').then();
    },
    // 登出
    userOnClose: (val, callback) => {
        AxiosServer('/v1/user/offlineUser', val, callback, '').then();
    },
    // 获取用户信息
    userInfo: (val, callback) => {
        AxiosServer('/v1/user/info', val, callback, 'GET').then();
    },
    // 修改用户头像
    updateImgUrl: (val, callback) => {
        AxiosServer('/v1/user/updateImgUrl', val, callback, '', true).then();
    },
};
const wallet = {
    // 用户存款
    userAccountPay: (val, callback) => {
        AxiosServer('/v1/account/pay', val, callback, '').then();
    },
    // 用户取款
    userAccountWithdraw: (val, callback) => {
        AxiosServer('/v1/account/withdraw', val, callback, '').then();
    },
    // 获取用户存款
    // findUserBalance: (val: any, callback: any) => {
    //     AxiosServer('/v1/account/findBalance', val, callback, 'GET').then()
    // },
    // 获取取款下限
    userfindChuRu: (val, callback) => {
        AxiosServer('/v1/account/findChuRu', val, callback, 'GET').then();
    },
    // 历史记录-存款
    myPay: (val, callback) => {
        AxiosServer('/v1/account/myPay', val, callback, 'GET').then();
    },
    // 历史记录-取款
    myWithdraw: (val, callback) => {
        AxiosServer('/v1/account/myWithdraw', val, callback, 'GET').then();
    },
    // 钱包交易记录
    payTransaction: (val, callback) => {
        AxiosServer('/v1/account/transaction', val, callback, 'GET').then();
    },
    // 个人页面交易记录
    personTransaction: (val, callback) => {
        AxiosServer('/v1/account/personTransaction', val, callback, 'GET').then();
    },
    // 设置法币
    setFiatType: (val, callback) => {
        AxiosServer('/v1/user/setFiatType', val, callback, '').then();
    },
    // 获取汇率
    rateAll: (val, callback) => {
        AxiosServer('/v1/userApi/rateAll', val, callback, 'GET').then();
    },
    // 获取币种
    getCurreny: (val, callback) => {
        AxiosServer('/v1/userApi/currency', val, callback, 'GET').then();
    },
    // 是否打开法币
    configCurrencyType: (val, callback) => {
        AxiosServer('/v1/account/configCurrencyType', val, callback, 'GET').then();
    },
};
const mainContent = {
    getContentFooter: (val, callback) => {
        AxiosServer('/v1/home/internationalConfig/footer', val, callback, 'GET').then();
    },
    getNotify: (val, callback) => {
        AxiosServer('/v1/user/notification/getNotify', val, callback, '').then();
    },
    notifyCleanAll: (val, callback) => {
        AxiosServer('/v1/user/notification/cleanAll', val, callback, 'GET').then();
    },
    playGame: (val, callback) => {
        const url = "/v1/user/game/goToGame";
        const cybetLanguage = localStorage.getItem('cybet-language');
        const countryCode = cybetLanguage ? cybetLanguage.split('_')[1] : "US";
        val.country = countryCode;
        AxiosServer(url, val, callback, 'POST').then();
    },
    getAllGameCompany: (val, callback) => {
        AxiosServer('/v1/userApi/queryAllOperation', val, callback, 'GET').then();
    },
    getAllGameListNew: (val, callback) => {
        AxiosServer('/v1/home/main/hgame', val, callback, 'GET').then();
    },
    getOurGames: (val, callback) => {
        AxiosServer('/v1/home/center/pageUserPlayedGames', val, callback, 'GET').then();
    },
    getTabList: (url, val, callback) => {
        AxiosServer(url, val, callback, 'GET').then();
    },
    gameTypeAll: (val, callback) => {
        AxiosServer('/v1/home/gameTypeAll', val, callback, 'GET').then();
    },
    // vip未读数
    queryVipRewardCount: (val, callback) => {
        AxiosServer('/v1/user/queryVipRewardCount', val, callback, 'GET').then();
    },
    getAdDes: (val, callback) => {
        AxiosServer('/v1/home/listAdvertis', val, callback, 'GET').then();
    },
    // banner获取所有活动
    selectAllActivityConfig: (val, callback) => {
        AxiosServer('/v1/home/selectAllActivityConfig', val, callback, 'POST').then();
    },
    // banner获取选中数据
    selectAllActivity: (val, callback) => {
        AxiosServer('/v1/home/selectAllActivity', val, callback, 'GET').then();
    },
    notificationUpdRead: (val, callback) => {
        AxiosServer('/v1/user/notification/updRead', val, callback, 'GET').then();
    },
    getPlayersVictory: (val, callback) => {
        AxiosServer('/v1/home/center/getPlayersVictory', val, callback, 'GET').then();
    },
    // 搜索游戏
    getListGame: (val, callback) => {
        AxiosServer('/v1/home/center/listGame', val, callback, 'POST').then();
    },
    // 收藏
    getFavoriteGame: (val, callback) => {
        AxiosServer('/v1/game/likeCollect/collectList', val, callback, 'POST').then();
    },
    // 子页面查询游戏厂商和数量
    listProviderGameCount: (val, callback) => {
        AxiosServer('/v1/home/header/listProviderGameCount', val, callback, 'GET').then();
    },
};
const userContent = {
    getMyInfo: (val, callback) => {
        AxiosServer('/v1/user/getMyInfo', val, callback, 'GET').then();
    },
    updateUser: (val, callback) => {
        AxiosServer('/v1/user/updateName', val, callback, '').then();
    },
    //修改登录密码
    updateLoginPassword: (val, callback) => {
        AxiosServer('/v1/user/updateLoginPassword', val, callback, '').then();
    },
    updatePayment: (val, callback) => {
        AxiosServer('/v1/user/updatePayment', val, callback, '').then();
    },
    getChatList: (val, callback) => {
        AxiosServer('/v1/user/userChat/getChatList', val, callback, '').then();
    },
    getChatListUserbet: (val, callback) => {
        AxiosServer('/v1/user/betRecord/userbet', val, callback, 'GET').then();
    },
    sendTip: (val, callback) => {
        AxiosServer('/v1/account/sendTip', val, callback, '').then();
    },
    changeEmail: (val, callback) => {
        AxiosServer('/v1/user/changeEmail', val, callback, '').then();
    },
    validateEmail: (val, callback) => {
        AxiosServer('/v1/user/validateEmail', val, callback, '').then();
    },
    validateCode: (val, callback) => {
        AxiosServer('/v1/mail/common/code', val, callback, '').then();
    },
    validateCodeRe: (val, callback) => {
        AxiosServer('/v1/user/mail/common/code', val, callback, '').then();
    },
    //验证密码
    validatePass: (val, callback) => {
        AxiosServer('/v1/user/validatePass', val, callback, '').then();
    },
    //发红包
    userChatRain: (val, callback) => {
        AxiosServer('/v1/user/userChat/rain', val, callback, '').then();
    },
    // 获取用户余额信息
    wsInfo: (val, callback) => {
        AxiosServer('/v1/user/wsinfo', val, callback, 'GET', true).then();
    }
};
const i18nApi = {
    getChatList: (val, callback) => {
        AxiosServer('/v1/home/internationalConfig/listGameTypes', val, callback, 'GET').then();
    },
    getListSysConfigs: (val, callback) => {
        AxiosServer('/v1/home/internationalConfig/footerI18n', val, callback, 'GET').then();
    },
    //会员等级i18n
    levelI18n: (val, callback) => {
        AxiosServer('/v1/user/levelI18n', val, callback, 'GET').then();
    },
};
const profileApi = {
    getGameHistory: (url, val, callback) => {
        AxiosServer(url, val, callback, 'POST').then();
    },
    getCurrentClientLogs: (val, callback) => {
        AxiosServer('/v1/user/currentClientLogs', val, callback, 'POST').then();
    },
    receivePrivilegeBonus: (val, callback) => {
        AxiosServer('/v1/user/receivePrivilegeBonus', val, callback, 'GET').then();
    },
    twoFaStartTwoAuth: (val, callback) => {
        AxiosServer('/v1/user/startTwoAuth', val, callback, 'POST').then();
    },
    twoFaCloseTwoAuth: (val, callback) => {
        AxiosServer('/v1/user/closeTwoAuth', val, callback, 'POST').then();
    },
    checkGoogleCode: (val, callback) => {
        AxiosServer('/v1/user/checkGoogleCode', val, callback, 'GET').then();
    },
    loginCheckGoogleCode: (val, callback) => {
        AxiosServer('/v1/user/googleCheck', val, callback, 'GET').then();
    },
    profileSome: (val, callback) => {
        AxiosServer('/v1/user/getMyInviteUserInfo', val, callback, 'POST').then();
    },
    levelPrivilegeList: (val, callback) => {
        AxiosServer('/v1/user/levelPrivilegeList', val, callback, 'GET').then();
    },
    getPrivilegeBonusList: (val, callback) => {
        AxiosServer('/v1/user/getPrivilegeBonusList', val, callback, 'GET').then();
    },
    HidenUsername: (val, callback) => {
        AxiosServer('/v1/user/updatePrivacy', val, callback, 'POST').then();
    },
    // 获取前一日分佣数值
    getYesterdayCommission: (val, callback) => {
        AxiosServer('/v1/user/getMyInviteUserRefer', val, callback, 'GET').then();
    },
    // 用户领取前一日分佣
    getYesterdayCommissionList: (val, callback) => {
        AxiosServer('/v1/user/userReferralReward', val, callback, 'POST').then();
    },
    // 根据年月周日查询用户游戏统计信息
    selectUserGameStatistics: (val, callback) => {
        AxiosServer('/v1/user/selectUserGameStatistics', val, callback, 'GET').then();
    },
};
const policyApi = {
    getChatRoomRules: (val, callback) => {
        AxiosServer('/v1/user/configure/chatRoomRules', val, callback, 'GET').then();
    },
    getFooterCon: (val, callback) => {
        AxiosServer('/v1/home/internationalConfig/footerCon', val, callback, 'GET').then();
    },
    //网站内容管理 - 用户使用协议查询
    baseContentQueryAgreement: (val, callback) => {
        AxiosServer('/v1/admin/config/querySingle', val, callback, 'GET').then();
    },
};
const VipBonus = {
    // 查询VIP返佣信息
    queryVipReferInfo: (val, callback) => {
        AxiosServer('/v1/user/queryVipReferInfo', val, callback, 'GET').then();
    },
    receivePrivilegeBonus: (val, callback) => {
        AxiosServer('/v1/user/vipReceiveReward', val, callback, 'GET').then();
    },
    querycalendar: (val, callback) => {
        AxiosServer('/v1/user/queryVipCalendar', val, callback, 'GET').then();
    },
    queryTypeReward: (val, callback) => {
        AxiosServer('/v1/user/queryTypeReward', val, callback, 'GET').then();
    },
    queryUnlockedLevelList: (val, callback) => {
        AxiosServer('/v1/user/queryUnlockedLevelList', val, callback, 'GET').then();
    },
    queryLockLevel: (val, callback) => {
        AxiosServer('/v1/user/queryLockLevel', val, callback, 'GET').then();
    },
    receiveUpgradeReward: (val, callback) => {
        AxiosServer('/v1/user/receiveUpgradeReward', val, callback, 'GET').then();
    },
};
const gameApi = {
    //游戏点赞和收藏量
    gameLikeCollectCount: (val, callback) => {
        AxiosServer('/v1/game/likeCollect/gameLikeCollectCount', val, callback, 'GET').then();
    },
    // 用户收藏和点赞游戏
    likeCollect: (val, callback) => {
        AxiosServer('/v1/game/likeCollect/likeCollect', val, callback, '').then();
    },
    // 游戏配置和rule
    cyGameConfig: (val, callback) => {
        AxiosServer('/v1/home/selectGameRule', val, callback, 'POST').then();
    },
    // 游戏公平性验证获取值
    gamefairess: (val, callback) => {
        AxiosServer('/api/fairness', val, callback, 'GET', false, true).then();
    },
    // 游戏公平性验证更新值
    gamefairessUpdate: (val, callback) => {
        AxiosServer('/api/fairness/change', val, callback, 'POST', false, true).then();
    },
};
const thirdApi = {
    twitch: (val, callback) => {
        AxiosServer('/v1/user/thirdLogin/twitch/authUrl', val, callback, 'GET').then();
    },
    google: (val, callback) => {
        AxiosServer('/v1/user/thirdLogin/google/authUrl', val, callback, 'GET').then();
    },
    Facebook: (val, callback) => {
        AxiosServer('/v1/user/thirdLogin/oauth2/Facebook', val, callback, 'GET').then();
    },
};
const testApi = {
    test: (val, callback) => {
        AxiosServer('/v1/user/test', val, callback, 'GET').then();
    }
};
const gameServerApi = {
    login: (val, callback) => {
        AxiosServer('/api/web-lobby/login', val, callback, 'GET', false, true).then();
    },
    fairnessHistory: (val, callback) => {
        AxiosServer('/api/fairness/history', val, callback, 'POST', false, true).then();
    },
};
export { login, wallet, mainContent, userContent, i18nApi, profileApi, policyApi, VipBonus, gameApi, thirdApi, testApi, gameServerApi };
