export default {
    main: {
        login: 'लॉग इन करें',
        signup: 'साइन अप करें',
        language: 'भाषा',
        toTop: 'शीर्ष पर जाएं',
        licence: 'लाइसेंस',
        profile: 'व्यक्तिगत प्रोफ़ाइल',
        nodata: 'कोई डेटा नहीं',
        logout: 'लॉग आउट',
        notify: 'सूचनाएँ',
        all: 'सभी',
        system: 'सिस्टम सूचना',
        activity: 'गतिविधियाँ',
        clear: 'सभी साफ करें',
        more: 'और लोड करें',
        wallet: 'बटुआ',
        deposit: 'जमा करें',
        withdraw: 'निकासी',
        trans: 'लेन-देन',
        currency: 'जमा मुद्रा',
        network: 'नेटवर्क',
        address: 'जमा पता',
        mini: 'न्यूनतम जमा',
        send: 'केवल USDT को इस जमा पते पर भेजें। सिक्के 6 नेटवर्क पुष्टियों के बाद स्वचालित रूप से जमा हो जाएंगे। स्मार्ट कॉन्ट्रैक्ट पते समर्थित नहीं हैं (संपर्क करें)।',
        withAddress: 'निकासी पता',
        withEnter: 'अपना पता दर्ज करें',
        tag: 'टैग',
        tagEnter: 'कृपया प्रविष्ट करें',
        amount: 'निकासी राशि',
        security: 'सुरक्षा कारणों से, बड़ी या संदिग्ध निकासी में ऑडिट प्रक्रिया के लिए 1-6 घंटे लग सकते हैं। आपके धैर्य के लिए धन्यवाद!',
        today: 'आज',
        week: 'इस हफ्ते',
        month: 'इस महीने',
        year: 'इस साल',
        myWallet: 'मेरा बटुआ',
        available: 'उपलब्ध',
        fee: 'शुल्क',
        chat: 'चैट',
        online: 'ऑनलाइन',
        message: 'संदेश दर्ज करें',
        rules: 'नियम'
    },
    login: {
        email: "कृपया ईमेल दर्ज करें",
        incorrect: "मेलबॉक्स प्रारूप गलत है",
        password: "कृपया पासवर्ड दर्ज करें",
        characters: "पासवर्ड की लंबाई 6 से 15 अंक या अक्षरों के बीच होनी चाहिए।",
        account: "खाता",
        bindAccount: 'बाइन्ड खाता',
        login: "लॉगिन",
        register: "रजिस्टर",
        userEmail: "आपका ईमेल",
        enterEmail: "अपना ईमेल दर्ज करें",
        userPas: "आपका पासवर्ड",
        enterPas: "अपना पासवर्ड दर्ज करें",
        forget: "क्या आप पासवर्ड भूल गए?",
        or: "या",
        agree: "मैंने शर्तों और नियमों को पढ़ लिया है और सहमत हूं।",
        resAndLogin: "रजिस्टर और लॉगिन",
        policy: "यह साइट reCAPTCHA द्वारा सुरक्षित है और Google की गोपनीयता नीति और सेवा शर्तें लागू होती हैं।"
    },
    profile: {
        password: "कृपया एक नया पासवर्ड दर्ज करें",
        long: "पासवर्ड में कम से कम एक अंक, अक्षर, या रिक्त स्थान होना चाहिए और इसकी लंबाई 6-15 वर्णों के बीच होनी चाहिए",
        newPas: "पुष्टिकरण पासवर्ड नया पासवर्ड से मेल नहीं खाता है",
        original: "कृपया मूल पासवर्ड दर्ज करें",
        personal: 'व्यक्तिगत सेटिंग्स',
        change: 'उपनाम और आपका डेटा बदलें',
        username: 'आपका उपयोगकर्ता नाम',
        hide: 'मेरा उपनाम छिपाएं',
        private: 'निजी प्रोफ़ाइल',
        closing: 'आपका खाता बंद करना',
        closeDatail: 'आप नीचे दिए गए "मेरा खाता बंद करें" बटन पर क्लिक करके अपना खाता बंद कर सकते हैं। आपके सभी डेटा स्थायी रूप से हटा दिए जाएंगे और भविष्य में पुनर्प्राप्ति की कोई संभावना नहीं होगी (जिसमें कोई भी वीआईपी लाभ शामिल है)।',
        closeMy: 'मेरा खाता बंद करें',
        social: 'सामाजिक',
        media: 'सोशल मीडिया कनेक्ट करें',
        connected: 'कनेक्ट किया गया',
        noConnect: 'कनेक्ट नहीं है',
        settings: 'सेटिंग्स',
        account: 'आपके खाते की सामान्य सेटिंग्स',
        email: 'ई-मेल',
        verify: 'कृपया अपने ई-मेल की पुष्टि करें',
        factor: 'दो-कारक प्रमाणीकरण',
        enable: 'अपने खाते को अनधिकृत पहुंच से बचाने के लिए दो-कारक प्रमाणीकरण सक्षम करें।',
        prohibit: 'निषेध',
        pas: 'पासवर्ड',
        current: 'आपका वर्तमान पासवर्ड',
        please: 'कृपया अपना वर्तमान पासवर्ड दर्ज करें',
        newPassword: 'नया पासवर्ड',
        paleseNew: 'कृपया अपना नया पासवर्ड दर्ज करें',
        repeat: 'पासवर्ड दोहराएं',
        pleaseCurrent: 'कृपया अपना वर्तमान पासवर्ड दर्ज करें',
        changePas: 'पासवर्ड बदलें',
        sessions: 'सत्र',
        devices: 'अपनी डिवाइस की जाँच करें',
        history: 'खेल इतिहास',
        referrals: 'रेफ़रल',
        VIP: 'वीआईपी कार्यक्रम',
        check: 'सभी लेनदेन की जाँच करें',
        allTrans: 'सभी लेन-देन',
        withdrawal: 'निकासी',
        timeframe: 'समय सीमा',
        today: 'आज',
        deposits: 'जमा',
        chackGame: 'अपने सभी खेलों की जाँच करें',
        chain: 'श्रृंखला'
    },
    components: {
        search: "खोजें",
        sort: "क्रमबद्ध करें:",
        popular: "लोकप्रिय",
        providers: "प्रदाता:",
        new: "नया",
        hot: "ट्रेंडिंग",
        notice: "अभी तक कोई नोटिस नहीं है।",
        enable: "सक्रिय करें",
        cybet: "Cybet.com Raw Entertainment B.V. का ट्रेडमार्क है, पंजीकरण संख्या 157205, Korporaalweg 10, Curacao में पंजीकृत, Curacao सरकार से ऑनलाइन गेमिंग के लिए लाइसेंस प्राप्त, लाइसेंस संख्या 8048/JAZ. Raw Entertainment Ltd, पंजीकरण संख्या HE421735, Voukourestiou, 25, Neptune House, 1st Floor, Flat/Office 11, Zakaki, 3045, Limassol, Cyprus में पंजीकृत है, यह Cybet समूह का सदस्य है और Raw Entertainment B.V. की ओर से भुगतान और संचालन एजेंट के रूप में कार्य करता है। (18+).",
        progress: "आपकी VIP प्रगति",
        discover: "CY.GAMES के विशेष VIP सिस्टम के साथ अंतिम गेमिंग अनुभव खोजें!",
        link: "आपका लिंक",
        share: "प्रत्येक मित्र के लिए जिसे आप आमंत्रित करते हैं, आपको $1,000.00 प्राप्त होता है, साथ ही उनकी शर्तों पर 25% तक कमीशन। चाहे वे जीतें या हारें, हमारे कैसीनो और स्पोर्ट्सबुक में लगातार कमीशन का आनंद लें। अभी कमाना शुरू करें!",
        referralLink: "आपका रेफरल लिंक",
        code: "आपका रेफरल कोड",
        yourReferrals: "आपके सभी रेफरल",
        receive: "प्राप्त करें",
        game: "सभी खेल",
        winnings: "जीत",
        loosing: "हानियाँ",
        wagered: "आपके खिलाड़ियों ने शर्त लगाई है",
        earned: "आपने कमाया है",
        nothing: "अभी तक कुछ भी दावा करने के लिए नहीं",
        join: "हमारे एलीट खिलाड़ियों के समुदाय में शामिल हों और ऑनलाइन गेमिंग के सर्वश्रेष्ठ का आनंद लें।",
        details: "विवरण",
        unverified: "असत्यापित",
        verified: "ईमेल सत्यापित",
        type: "लेन-देन का प्रकार",
        date: "तारीख",
        total: "कुल दांव",
        methods: "भुगतान विधियाँ",
        status: "स्थिति",
        device: "डिवाइस",
        location: "स्थान",
        IP: "IP पता",
        last: "अंतिम उपयोग",
        tableGame: "खेल",
        bet: "दांव",
        multiplayer: "मल्टीप्लेयर",
        profit: "लाभ",
        searchGame: "खेल खोजें",
        provider: "प्रदाता",
        ourGames: "हमारे खेल",
        allWins: "सभी जीत",
        myBets: 'मेरे बेट',
        luckyWins: "भाग्यशाली जीत",
        bigWins: "बड़ी जीत",
        latest: "नवीनतम दांव",
        player: "खिलाड़ी का नाम",
        time: "समय",
        amount: "राशि",
        coefficent: " गुणांक"
    },
    wallet: {
        noAdress: 'पता खाली नहीं हो सकता',
        submit: 'निकासी अनुरोध प्रस्तुत किया गया है, पुष्टि की प्रतीक्षा कर रहा है...',
        range: 'कृपया निकासी सीमा के भीतर एक राशि दर्ज करें'
    }
};
