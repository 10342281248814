import { Decimal } from 'decimal.js';
export function decimal_add(x, y) {
    if (!x) {
        x = 0;
    }
    if (!y) {
        y = 0;
    }
    const xx = new Decimal(x);
    const yy = new Decimal(y);
    return xx.plus(yy).toNumber();
}
// 减
export function decimal_sub(x, y) {
    if (!x) {
        x = 0;
    }
    if (!y) {
        y = 0;
    }
    const xx = new Decimal(x);
    const yy = new Decimal(y);
    return xx.sub(yy).toNumber();
}
// 除
export function decimal_div(x, y) {
    if (!x) {
        x = 0;
    }
    if (!y) {
        return 0;
    }
    const xx = new Decimal(x);
    const yy = new Decimal(y);
    return xx.div(yy).toNumber();
}
//乘
export function decimal_mul(x, y) {
    if (!x) {
        x = 0;
    }
    if (!y) {
        y = 0;
    }
    const xx = new Decimal(x);
    const yy = new Decimal(y);
    return xx.mul(yy).toNumber();
}
